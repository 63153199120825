@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@tailwind base;
@layer base {
	html {
		@apply text-black;
	}
}
@tailwind components;
@tailwind utilities;


body {
	margin: 0;
	font-family: 'Nunito', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: Monaco, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
pre {
	font-family: monospace;
}

border-1:hover {
	border-width: 1px;
}
.border-1 {
	border-width: 1px;
}

.border-3 {
	border-width: 3px;
}

.border-b-1 {
	border-bottom-width: 1px;
}

.border-t-1 {
	border-top-width: 1px;
}

.border-r-1 {
	border-right-width: 1px;
}

.border-l-1 {
	border-left-width: 1px;
}

.py-1\/2 {
	padding-top: 0.12rem;
	padding-bottom: 0.125rem;
}

.table-container {
	@apply mt-2;
	@apply border-1;
	@apply rounded-lg;
	@apply overflow-clip;
}

table {
	@apply w-full;
}

thead {
	@apply text-left;
	@apply border-b-1;
}

th {
	@apply px-3;
	@apply py-2;
	@apply text-left;
	@apply text-xs;
	@apply uppercase;
	@apply tracking-wider;
	@apply bg-white;
}

/* py-1 ps-3 py-1 ps-3 py-1 ps-3 py-1 ps-3 py-1 ps-3 */

th:not(:last-child) {
}

/* table > tbody > tr:last-child > td {
  @apply pb-10;
} */

tr {
	@apply even:bg-gray-50;
	@apply odd:bg-white;
}

tr:not(:last-child) {
	@apply border-dashed;
	@apply border-b-1;
}

tr:hover {
}

table > tbody > tr > td:not(:last-child) {
}

tbody > tr > td {
	@apply px-3;
	@apply py-2;
	@apply text-sm;
}

#todos {
	@apply p-8;
	@apply h-full;
	@apply overflow-y-scroll;
}

#todos > ul {
	padding-bottom: 25px;
}

.no-scrollbar {
	@apply overflow-y-scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.h-1\/12 {
	height: 8.3333%;
}

.h-2\/12 {
	height: 16.6667%;
}

.h-9\/12 {
	height: 75%;
}

.h-10\/12 {
	height: 83.3333%;
}
